<template>
  <div class="page flex-col">
    <header>
      <van-nav-bar
              title="最新活动"
              left-text=""
              left-arrow
              @click-left="onClickLeft"
      />
    </header>
    <section>
    <div class="box_1 flex-row">
      <div class="group_1 flex-col">

      </div>
      <div class="group_2 flex-col">
        <span class="text_5">{{activity.title}}</span>
        <span class="text_6" style="text-indent: 2em;letter-spacing: 0.1em;">
          {{activity.content}}
        </span>

      </div>
    </div>
    </section>
  </div>
</template>
<script>
  import {queryActivity} from "@/api/user";
export default {
  data() {
    return {
      activity: {}
    };
  },
  mounted() {
    queryActivity().then(response => {
      if(response.code==200){
        if(response.data){
          this.activity = response.data[0]
        }
      }
    });
  },
  methods: {
    onClickLeft(){
      this.$router.go(-1)
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />